import React from "react"
import Layout from "../../components/layout"
import { Container } from "reactstrap"
import Head from "../../components/head"
import { Link } from "gatsby"
import LazyLoad from "react-lazyload"
import catImage from "../../images/category/101.jpg"
import catNum1Image from "../../images/dummies/charliebasic.jpg"
import catNum2Image from "../../images/dummies/charlieu1.jpg"
import catNum3Image from "../../images/dummies/charlieu2.jpg"
import catNum4Image from "../../images/dummies/charlieu3.jpg"
// import catNum5Image from "../../images/category/71.jpg"
// import catNum6Image from "../../images/category/84.jpg"
// import catNum7Image from "../../images/category/85.jpg"
// import catNum8Image from "../../images/category/87.jpg"
// import catNum9Image from "../../images/category/69.jpg"
// import catNum10Image from "../../images/category/58.jpg"
// import catNum11Image from "../../images/category/70.jpg"
// import catNum12Image from "../../images/category/77.jpg"
// import catNum13Image from "../../images/category/80.jpg"
// import catNum14Image from "../../images/category/83.jpg"
// import catNum15Image from "../../images/category/81.jpg"
// import catNum16Image from "../../images/category/79.jpg"
// import catNum17Image from "../../images/category/86.jpg"

const Charlie = () => {
  //   const CatImage = "../images/category/101.jpg"
  const CatName = "BUY YOUR OWN CHARLIE McCARTHY VENTRILOQUIST DUMMY TODAY!"

  const CatTagLine = "Charlie McCarthy dummies are available in 4 different levels"

  const CatDesc =
    "Edgar Bergen's sidekick, Charlie McCarthy, is one of the most famous ventriloquist dummies of all time. Charlie was " +
    "loved on radio and television and in the movies. While Edgar Bergen's Charlie McCarthy is in the Smithsonian, you can buy " +
    "your own Charlie McCarthy ventriloquist dummy today!" +
    "ThrowThings.com offers four versions of Charlie McCarthy who is sometimes accidentally referred to as Charlie McCartney. We " +
    "have the Basic Charlie McCarthy Ventriloquist Doll, the Standard Upgrade " +
    "Charlie McCarthy Ventriloquist Dummy, the Deluxe Upgrade Charlie McCarthy Ventriloquist Dummy, and the Super Deluxe Upgrade " +
    "Charlie McCarthy Ventriloquist Dummy. All versions of the Charlie McCarthy dummies include Charlie's top hat and monocle! " +
    "Before deciding on which version of Charlie McCarthy to purchase we suggest that you watch the short video at the bottom of this " +
    "page to see the differences between the various levels of ventriloquist dummy dolls for sale and what makes our figures so special."

  const categories = [
    {
      catNum: 1,
      categoryName: "Charlie McCarthy Ventriloquist Dummy Doll",
      categoryTag: "Stuffed Body, Pull String To Make Mouth Move $59.99",
      categoryImage: catNum1Image,
      categoryLink: "/products/charlie-mccarthy-basic-ventriloquist-dummy-doll",
    },
    {
      catNum: 2,
      categoryName: "Charlie McCarthy Standard Upgrade Ventriloquist Dummy",
      categoryTag: "Moving Head, Trigger Action Mouth & More $174.99",
      categoryImage: catNum2Image,
      categoryLink: "/products/charlie-mccarthy-standard-upgrade-dummy",
    },
    {
      catNum: 3,
      categoryName: "Charlie McCarthy Deluxe Upgrade Ventriloqust Dummy",
      categoryTag: "Moving Eyes & Head, Lifelike Wig, More $294.99",
      categoryImage: catNum3Image,
      categoryLink: "/products/charlie-mccarthy-deluxe-upgrade-dummy",
    },
    {
      catNum: 4,
      categoryName: "Charlie McCarthy Super Deluxe Upgrade Ventriloquist Dummy",
      categoryTag: "Moving Eyes and Head PLUS Moving Brows! $384.99",
      categoryImage: catNum4Image,
      categoryLink: "/products/charlie-mccarthy-super-deluxe-upgrade-dummy",
    },
    // {
    //   catNum: 12,
    //   categoryName: "Hand Puppets",
    //   categoryTag: "Silly Puppets & More",
    //   categoryImage: catNum12Image,
    //   categoryLink: "/hand-puppets",
    // },
    // {
    //   catNum: 9,
    //   categoryName: "Marionettes",
    //   categoryTag: "Made In Prague",
    //   categoryImage: catNum9Image,
    //   categoryLink: "/marionettes",
    // },
    // {
    //   catNum: 11,
    //   categoryName: "Accessories & Replacement Stuff",
    //   categoryTag: "New Things For Your Old Dummy!",
    //   categoryImage: catNum11Image,
    //   categoryLink: "/accessories-replacement-stuff",
    // },
    // {
    //   catNum: 5,
    //   categoryName: "Female Ventriloquist Dummies",
    //   categoryTag: "Standard and Deluxe Upgrade Converted Dummies!",
    //   categoryImage: catNum5Image,
    //   categoryLink: "/female-ventriloquist-dummies",
    // },
    // {
    //   catNum: 6,
    //   categoryName: "Gramps & Granny",
    //   categoryTag: "Two New Characters From ThrowThings",
    //   categoryImage: catNum6Image,
    //   categoryLink: "/gramps-granny",
    // },
    // {
    //   catNum: 8,
    //   categoryName: "Professional Ventriloquist Dummies",
    //   categoryTag: "Carved Wooden Dummies & More",
    //   categoryImage: catNum8Image,
    //   categoryLink: "/professional-ventriloquist-dummies",
    // },
    // {
    //   catNum: 10,
    //   categoryName: "Books, DVDs, Cases & Stands",
    //   categoryTag: "Learn To Be A Ventriloquist",
    //   categoryImage: catNum10Image,
    //   categoryLink: "/books-dvds-cases-and-stands",
    // },
    // {
    //   catNum: 7,
    //   categoryName: "Jeff Dunham Stuff",
    //   categoryTag: "Walter, Achmed, Peanur & Little Jeff Stuff",
    //   categoryImage: catNum7Image,
    //   categoryLink: "/jeff-dunham-stuff",
    // },
    // {
    //   catNum: 13,
    //   categoryName: "Stretch Ducks",
    //   categoryTag: "So Unusual Its In A Class Of Is Own",
    //   categoryImage: catNum13Image,
    //   categoryLink: "/stretch-ducks",
    // },
    // {
    //   catNum: 14,
    //   categoryName: "Bonus Bundles",
    //   categoryTag: "Get More Than Just A Dummy!",
    //   categoryImage: catNum14Image,
    //   categoryLink: "/bonus-bundles",
    // },
    // {
    //   catNum: 15,
    //   categoryName: "Limited Editions",
    //   categoryTag: "For The Collector",
    //   categoryImage: catNum15Image,
    //   categoryLink: "/limited-editions",
    // },
    // {
    //   catNum: 16,
    //   categoryName: "Upgrade Certificates",
    //   categoryTag: "Great Gift For Someone With A Basic Ventriloquist Dummy",
    //   categoryImage: catNum16Image,
    //   categoryLink: "/upgrade-certificates",
    // },
    // {
    //   catNum: 17,
    //   categoryName: "Puppet-on-a-Stick",
    //   categoryTag: "Puppet-on-a-Stick, Sea Squad, Puppet-on-a-Pen",
    //   categoryImage: catNum17Image,
    //   categoryLink: "/puppet-on-a-stick",
    // },
  ]
  return (
    <Layout>
      <Head
        title="Charlie McCarthy Ventriloquist Dummies"
        description="Charlie McCarthy dummies are available for sale from ThrowThings.com in 4 different versions. Charlie comes completely dressed with his top hat and monocle."
      />
      <Container>
        {/* <!-- section start --> */}
        <section className="section-b-space ratio_asos">
          <div className="collection-wrapper">
            <div className="container">
              <div className="row">
                <div className="collection-content col">
                  <div className="page-main-content">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="top-banner-wrapper">
                          {/* <Link to="#"> */}
                          <img
                            src={catImage}
                            className="img-fluid lazyload"
                            alt={CatTagLine}
                          />
                          {/* </Link> */}
                          {/* <Link to="#"><img src={require("../assets/images/mega-menu/2.jpg")} className="img-fluid blur-up lazyload" alt=""/></Link> */}
                          <div className="top-banner-content small-section">
                            <h4>{CatName}</h4>
                            <h5>{CatTagLine}</h5>
                            <p>{CatDesc}</p>
                          </div>
                        </div>
                        <div className="collection-product-wrapper">
                          <div className="product-wrapper-grid">
                            <div className="row">
                              {categories.map(category => (
                                <div
                                  key={category.catNum}
                                  className="col-xl-3 col-md-6 col-grid-box"
                                >
                                  <div className="product-box">
                                    <div className="img-wrapper">
                                      <div className="front">
                                        <Link to={category.categoryLink}>
                                          <LazyLoad>
                                            <img
                                              src={category.categoryImage}
                                              className="img-fluid lazyload bg-img"
                                              alt={category.categoryName}
                                            />
                                          </LazyLoad>
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="product-detail">
                                      <div>
                                        <Link to={category.categoryLink}>
                                          <h4>{category.categoryName}</h4>
                                          <h6>{category.categoryTag}</h6>
                                          <button class="btn btn-solid">More Information</button>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <section>
                          <h5>ThrowThings.com is your number one store to buy ventriloquist dummies. We have many different characters and levels in stock and ready to ship including Edgar Bergan's Charlie McCarthy. To see the differences between the various levels of ventriloquist dolls for sale and what makes our figures so special please watch the following short video. (Just click on the arrow.)</h5>
                          <section className="text-center">
                            <iframe title="An Introduction To Our Ventriloquist Dummies" width="320" height="265" src="https://www.youtube-nocookie.com/embed/33u9yc72gYE?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          </section>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- section End --> */}
      </Container>
    </Layout>
  )
}

export default Charlie